.detail{
    margin-top: 150px !important;
    width: 50%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 0px;
    padding-top: 40px;
    transition: all 0.3s ease;
    /*cursor: pointer;*/
    position: relative;
    overflow: hidden;
    margin: 10px;
}

h2 {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    margin-bottom: 10px;
    color:red;
}

h3 {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    margin-top: 10px;
}

h4 {
    font-size: 14px;
    text-align: center;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    margin: 10px;
    margin-top: 10px;
}
.activitiesTitle h2{
    font-size: 1rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    margin-top: 55px;
    margin-bottom: 10px;
    color: green;
}

.activities{
    display: flex;
    width: 90%;
    gap: 20px;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    transition: all 0.3s ease;
    /*cursor: pointer;*/
    position: relative;
    margin: 10px;
    background-color: lightgrey;
}

.flag{
    width: 300px;
    height: 200px;
    align-self: center;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    transition: all 0.3s ease;
    border-radius: 20px;
}

