.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-top: 0px;
  /*margin-bottom: 200px;*/
  max-width: 90%;
  padding-top: 0px;
}

.filters{
  align-content: center;
  margin: 150px;
  margin-left: 250px;
  margin-right: 0px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  background-color: lightgrey;
  padding: 10px;
  box-sizing: border-box;
  color:black;
  border-radius: 20px;
}

select
{
  width: 150px;
  height: 30px;
  margin: 10px;
  box-sizing: border-box;
}

.pagination{

    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 40%;
  margin-bottom: 25px;
}

.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@media only screen and (max-width :480px) {
  .filters{
    margin-top:.5rem;
    margin-bottom:.5rem;
    width:auto;
    font-size:.8rem;
    padding:.5rem;
    border-radius:.5rem;
    text-align:center;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
  select{
    width:auto;
    height:auto;
    margin:.5rem;
    box-sizing:border-box;
    font-size:.8rem;
  }
}
