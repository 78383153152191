.card{
    margin-top: 30px;
    width: 20%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 0px;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin: 10px;
}

h2 {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    margin-bottom: 10px;
}

h3 {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    margin-top: 10px;
}

@media only screen and (max-width :480px) {
    .card{
        width:auto;
        margin:auto;
        margin-top:.5rem;
        margin-bottom:.5rem;
        border-radius:.5rem;
        box-shadow:none;
        background-color:#fff;
        display:flex;
        flex-direction:column;
        justify-content:center;
        padding:.5rem;
        transition:none;
        cursor:pointer;
        position:relative;
        overflow:hidden;
    }
    h2{
        font-size:.8rem;
        font-weight:bold;
        text-align:center;
        font-family:'Arial', sans-serif;
        margin:.5rem;
    }
    h3{
        font-size:.8rem;
        font-weight:bold;
        text-align:center;
        font-family:'Arial', sans-serif;
        margin:.5rem;
    }
}