.searchBar {}

.input {
    width: 25%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    margin-right: 10px;
}

.button {
    width: 15%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-color: white;
    padding: 0 10px;
    font-size: 16px;
    background-color: #87cb82;
    color: white;
    cursor: pointer;
}