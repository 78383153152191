.container{
    display: flex;
    padding-bottom: 200px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /*height: 100%;*/
    background-color: #f5f5f5;
    padding: 20px;
}
