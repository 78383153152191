/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.landing {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
    background-image: url(../../img/countries-landing.jpg);
    background-size: cover;
    }
    h1 {
        font-size: 2rem;
        font-family: Arial, Helvetica, sans-serif;
        color: #fff;
        text-align: center;
        text-shadow: 0 0 10px #000;
        margin-bottom: 1rem;
        margin-top: -150px;
        padding-top: 0px;
    }

    .btn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 2.3rem;
        width: 13rem;
        background-color: #8bcf86;
        color: white;
        font-weight: bold;
        text-shadow: black 0.1em 0.1em 0.2em;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1rem;
        }
        .btn:hover{
            background-color: #6abf6a;
            cursor: pointer;
        }

        .logo{
            margin-top: -150px;
            margin-bottom: 180px;
            padding: 0px;
        }

