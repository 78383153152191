.form{
    margin-top: 30px;
    margin-bottom: 25px;
    padding-top: 180px;
    display: flex;
    justify-content: center;
    width: 30%;
    height: auto;
    background-color: lightgrey;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
}

.form input {
    margin: 5px;
    width: 200px;
    height: 25px;
    font-size: 17px;
    font-family: "Arial, Helvetica, sans-serif";
    border-radius: 5px;
}

label {
    margin: 100px;
    font-size: 15px !important;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif!important;
    text-transform: uppercase;
}

.danger{
    color: red;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif!important;
    border-color: red;
    border-width: 3px;
}

#title{
    font-size: 1.7em;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif!important;
    text-shadow: none;
    color:black;

}

.divs{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.divsLabels{
    flex-direction: column;
    align-items: center;
    margin: 10px;
    text-align: center;
    margin-bottom: 10px;
}

.selectCountriesMiniCards{
    height: 100%;
    margin: 10px;
    margin-top: 400px;
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif!important;
    font-size: 12px;

}

.buttonX{
    margin: 10px;
    width: 23px;
    height: 23px;
    font-size: 12px;
    font-family: "Arial, Helvetica, sans-serif";
    border-radius: 5px;
    margin-left: auto;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif!important;
    align-self: center;
}

.countriesSelected{
    display: flex;
    width: 100%;
    flex-direction: row;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    z-index: -1;
}

.difficultyLevel{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 35px;

}

@media only screen and (max-width: 600px) { .form { width: 100%; } }